import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
// import logo from "../logo.svg";
import Image from "../image/12051-3.jpg";
// import Image from "../image/2220082-4.jpg";

import logo from "../image/Mshari_Logo .png";

import Grid from "@material-ui/core/Grid";


const useStyles = makeStyles((theme) => ({
  gridheight:{
      height:"100vh",
      backgroundColor:"#121632",
      backgroundImage: `url(${Image})`,
      backgroundPosition: "center center",
      backgroundSize: "cover",

      color:"white",
     
      
  },
  h1:{
      fontSize:"80px"
  }

}));

export default function MediaControlCard() {
  const classes = useStyles();
  const theme = useTheme();

  return (
  
      <Grid container
      direction="row-reverse"
      justify="center"
      alignItems="center" spacing={0} className={classes.gridheight}>
     <Grid item xs={12} >
     <img src={logo} className="App-logo" alt="logo" />
     <h2   >The Real Estate of Tomorrow</h2>
<h1  className={classes.h1} >UNDER CONSTRUCTION</h1>
<h2>info@mshari.com</h2>

     </Grid>
      </Grid>
    
  );
}
// Coming Soon